@keyframes bubbleUp {
    0% {
        /* bottom: 110px; */
        -webkit-transform: scale(.9) translateY(0px);
        opacity: 0
    }
    1% {
        /* bottom: 110px; */
        -webkit-transform: scale(.7) translateY(20px);
        opacity: 0
    }
    30% {
        /* bottom: 110px; */
        -webkit-transform: scale(.8) translateY(50px);
        opacity: 1
    }
    95% {
        /* bottom: 545px; */
        -webkit-transform: scale(.9) translateY(100px);
        opacity: 1
    }
    99% {
        /* bottom: 550px; */
        -webkit-transform: scale(1) translateY(20px);
        opacity: 0
    }
    100% {
        /* bottom: 110px; */
        -webkit-transform: scale(.9) translateY(10px);
        opacity: 0
    }
}

@keyframes marquee {
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(-90%);
    }
}

@keyframes flutter {
    0% {
        transform: translateX(0);
        bottom: -100px;
        opacity: 1;
    }
    50% {
        transform: translateX(100px);
        opacity: 0.5;
    }
    100% {
        transform: translateX(0px);
        bottom: 100%;
        opacity: 0;
    }
}