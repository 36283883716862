.btn {
  color: white;
  background: black;
  min-width: 125px;
  padding: 16px 28px;
  border-radius: 12px;
  font-weight: 600;
  font-size: 15px;
  line-height: 24px;
}

.btn--co-yellow {
  background: #ffd66a;
  color: #3c268c;
}

.btn--co-cornflower-blue {
  background: #7e5ff2;
}
.btn--co-lightpurple {
  background: rgba(236, 230, 255, 1);
  color: rgba(60, 38, 140, 1);
}
.btn--co-cornflower-blue:disabled {
  background: #e4ddff;
}
.btn--co-cornflower-blue:disabled:hover {
  background: #e4ddff;
}

.btn--co-cornflower-blue:hover {
  background: #b209cd;
  transition: all 0.3s ease-in-out;
}

.btn--co-cosmos {
  color: #3c268c;
  background: #ffd0cf;
}

.btn--co-cosmos:hover {
  background: #ff9b9a;
  transition: all 0.3s ease-in-out;
}

.btn--co-cosmos:disabled {
  background: #ffd0cf;
  color: #3c268c;
}

.btn--co-dark-purple {
  background: #4824c1;
}

.btn--co-dark-purple:hover {
  background: #88079c;
  transition: all 0.3s ease-in-out;
}

@media (max-width: 850px) {
  .btn {
    font-size: 12px;
    min-width: 110px;
    padding: 8px 18px;
  }
}
