@media (max-width: 758px) {
  .nl__card-container {
    flex-direction: column;
  }
  .nl__card-content {
    height: fit-content;
  }
  .nl__card-right-stp2 {
    padding-right: 1rem;
  }
  .nl__card-left {
    width: 100%;
    height: 7.8rem;
    z-index: 1;
  }
  .nl__card-image {
    height: 100%;
    background-image: url("../../assets/images/modal-mobile2.png");
    position: absolute;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
  }
}

@media (max-width: 628px) {
  .nl__firsttext {
    font-size: 0.8rem;
    line-height: 59.84px;
    margin-bottom: 0;
  }
  .nl__secondtext {
    font-size: 1.375rem;
    line-height: 28px;
  }

  .nl__badges-image {
    width: 7rem;
  }
}

@media (max-width: 463px) {
  .nl__card-content {
    /* height: 640px; */
    height: fit-content;
  }

  .nl__image img {
    width: 18rem;
  }

  .nl__image-container {
    margin-right: 0;
  }
  .nl__card-right {
    padding: 2.6rem 1.5rem;
  }
  .nl__card-tab-group {
    display: flex;
    flex-direction: column;
  }
  .nl__card-input {
    font-size: 13px;
    padding: 16px 14px;
  }
  .nl__card-headtext {
    font-size: 20px;
    line-height: 27px;
  }
  .nl__card-selected-subtext {
    font-size: 12px;
    line-height: 19px;
  }
  .nl__card-emailbutton {
    bottom: 5px;
  }
}
