.nl__card-content {
  font-size: 16px;
  color: #58459c;
  line-height: 24px;
  letter-spacing: -0.01em;
  width: 100%;
  max-width: 52.4rem;
  /* max-height: 90vh; */
  /* max-height: 626px; */
  /* height: 580px; */
  /* height: 532px; */
  height: 32.25rem;
  overflow-x: hidden;
  background: #4824c1;
  /* background-image: url("../../assets/images/girl-on-chair.png"); */
  box-shadow: 0px 150px 70px rgba(60, 38, 140, 0.1);
  background-position: center;
  background-repeat: no-repeat;
  backdrop-filter: blur(4px);
  border-radius: 26px;
  position: relative;
}

.nl-close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  background: transparent;
}

.nl__card-container {
  display: flex;
  background: white;
  height: 100%;
  /* align-items: center;
  justify-content: center; */
}

.nl__card-image {
  height: 32.6rem;
  /* max-height: 100%; */
  background-image: url("../../assets/images/modal-left.png");
  position: absolute;
  background-size: cover;
  background-position: top;
  background-repeat: no-repeat;
  width: 100%;
  top: -1px;
}

.nl__card-headtext {
  font-family: General Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 1.5rem;
  line-height: 32px;
  color: #3c268c;
}
.nl__card-subtext {
  font-size: 0.9rem;
  line-height: 22px;
  margin-top: 1rem;
}

.nl__card-left {
  position: relative;
  width: 82%;
}

.nl__card-right-stp2 {
  padding: 2.6rem 1rem;
  padding-right: 4rem;
}

.nl__card-right {
  padding: 2.6rem 2rem;
}

.nl__error-msg {
  font-size: 13px;
  color: red;
}

.nl__name-group {
  display: flex;
  align-items: center;
  margin-top: 20px;
}

.nl__card-tab {
  display: flex;
  align-items: center;
  margin: 0.3rem 0.3rem;
  padding: 15px 13px;
  background: #ffffff;
  border: 1px solid transparent;
  box-shadow: 0px 3px 6px rgba(170, 149, 245, 0.2);
  border-radius: 12px;
  width: fit-content;
  cursor: pointer;
}

.nl__card-tab-active {
  display: flex;
  align-items: center;
  margin: 0.3rem 0;
  padding: 15px 13px;
  background: #fff3f3;
  border: 1px solid #ff9b9a;
  border-radius: 12px;
  width: fit-content;
  box-shadow: 0px 3px 6px rgba(170, 149, 245, 0.2);
  cursor: pointer;
}
.nl__card-tab-active p {
  margin-left: 13px;
  font-family: DM Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #3c268c;
  letter-spacing: 0.01em;
}

.nl__card-tab-selected {
  background: #ffefef;
  padding: 15px 13px;
  border: 1px solid #ffc2c9;
  box-sizing: border-box;
  border-radius: 8px;
  margin-bottom: 1rem;
  font-family: DM Sans;
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 22px;
  color: #ff6d6b;
  width: fit-content;
}

.nl__card-selected-subtext {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 22px;
  color: #5d4ba0;
  margin-top: 1rem;
}

.nl__close-button {
  margin-top: 1rem;
}

.nl__card-tab p {
  margin-left: 13px;
  font-family: DM Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #3c268c;
  letter-spacing: 0.01em;
}

.nl__card-choose {
  font-family: DM Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 24px;
  color: #5d4ba0;
  margin: 13px 0;
}

.nl__card-tab-group {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  margin-bottom: 1.5rem;
}

.nl__card-info {
  display: flex;
  align-items: center;
  margin-top: 18px;
}
.nl__card-info p {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: #7464b3;
  margin-left: 8px;
}

.nl__card__input-group {
  width: 100%;
  margin-top: 10px;
  position: relative;
}

.nl__card-input {
  width: 100%;
  padding: 17px 14px;
  background: #ffffff;
  border: 2px solid #f1edff;
  box-sizing: border-box;
  box-shadow: 0px 3px 6px rgba(170, 149, 245, 0.05);
  border-radius: 12px;
  color: #3c268c;
  font-family: DM Sans;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}
.nl__card-input-red {
  width: 100%;
  padding: 17px 14px;
  background: #ffffff;
  border: 2px solid red;
  box-sizing: border-box;
  box-shadow: 0px 3px 6px rgba(170, 149, 245, 0.05);
  border-radius: 12px;
  color: #3c268c;
  font-family: DM Sans;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}

.nl__card-emailbutton {
  position: absolute;
  bottom: 6px;
  right: 6px;
}

#emailbutton {
  padding: 13px 4px;
  border-radius: 10px;
  line-height: unset;
  font-weight: 300;
}

.nl__card-input::placeholder {
  color: #e4ddff;
}

.nl__card-input:focus {
  border: 2px solid #e6dfff !important;
}
.nl__card-success-message {
  margin-top: 2rem;
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 32px;
  letter-spacing: -0.02em;
  color: #5d4ba0;
}

/* New design */
.nl__pattern {
  width: 100%;
  position: absolute;
  left: -23px;
  top: 30px;
  z-index: -1;
}

.nl__image {
  /* display: flex;
  justify-content: center; */
  width: 23rem;
}

.nl__line {
  position: absolute;
  bottom: 22px;
  z-index: -1;
}

.nl__firsttext {
  font-family: "DM Sans";
  font-weight: 400;
  color: white;
  font-size: 1.225rem;
  text-align: center;
  margin-bottom: 1rem;
}

.nl__firsttext span {
  font-weight: 700;
}

.nl__secondtext {
  font-family: "General Sans";
  color: #ffa6b0;
  font-weight: 600;
  text-align: center;
  font-size: 1.75rem;
  line-height: 32px;
  margin-bottom: 1rem;
}
.nl__secondtext span {
  color: white;
}

.nl__image-container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: -1;
}
.nl__image-container img {
  /* width: 100%; */
}

.nl__thirdtext {
  font-weight: 700;
  text-align: center;
  font-size: 0.9rem;
  /* line-height: 59.84px; */
  color: white;
  /* position: absolute;
  left: 43%;
  bottom: 61px; */
}

.nl__bottom {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 1rem;
  position: absolute;
  transform: translate(28%, 10px);
  bottom: 26px;
}

.nl__badges {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 1rem;
  position: absolute;
  transform: translate(28%, 10px);
  bottom: 26px;
}

.nl__badges-image {
  width: 9.2rem;
}

.nl__action_button {
  color: #fad89b;
  position: absolute;
  bottom: 8px;
  right: 26px;
}
.nl__action_button :hover {
  color: #ffc838;
}

.nl__action_button p {
  font-family: "Space Grotesk";
  /* text-decoration: underline; */
  line-height: 59.84px;
  font-size: 1.125rem;
  display: flex;
  align-items: center;
  position: relative;
  gap: 5px;
  cursor: pointer;
}

.nl__action_button p::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 17px; /* Adjust to position the underline as needed */
  width: 100%;
  height: 1px;
  background: #ffc838;
  display: none;
}
/* When i hover on nl__action_button, let the after display */
.nl__action_button:hover p::after {
  display: block;
}

.nl__button-mobile p {
  /* line-height: 59.84px; */
  font-size: 1.125rem;
  display: flex;
  align-items: center;
  position: relative;
  gap: 5px;
  color: #fad89b;
}

.nl__button-mobile p::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 2px; /* Adjust to position the underline as needed */
  width: 100%;
  height: 1px;
  background: #fad89b;
}
