.wicon {
  width: 36px;
  height: 36px;
  color: inherit;
  border-radius: 50%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background: #e4ddff;
}
