.cursor-pointer {
  cursor: pointer;
}

.pos-rel {
  position: relative;
}

.flx {
  display: flex;
}

.flx-1 {
  flex: 1;
}

.flx-sb {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.flx-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.max-page-width {
  margin-left: auto;
  margin-right: auto;
  max-width: 1150px;
  padding-left: 26px;
  padding-right: 26px;
}

.react-reveal {
  will-change: transform, opacity;
}

.txt-highlight {
  color: #7e5ff2;
}

.ReactModal__Overlay {
  /* z-index: 1000;
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  padding: 0.5rem; */
  align-items: center;
  background: #7e5ff266;
  display: flex;
  inset: 0;
  justify-content: center;
  padding: 0.5rem;
  position: fixed;
  z-index: 1000;
}
