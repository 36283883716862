.page-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #ffffff;
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* target svg in .page-overlay */
.page-overlay svg {
  width: 100px;
  height: 100px;
}

.page-gradient {
  position: absolute;
  top: 0;
  left: 0;
}
