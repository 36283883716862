* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  font-family: inherit;
  font-weight: 400;
}

a,
a:active,
a:hover,
span {
  font-size: inherit;
  font-family: inherit;
  font-weight: inherit;
  text-decoration: none;
  color: inherit;
}

a,
a:hover,
a:focus,
a:active {
  text-decoration: none;
}

ul {
  padding: 0;
}

button {
  cursor: pointer;
  border: none;
}

*:focus {
  outline: 0 !important;
  border-color: #7e5ff2;
}

*:focus:not(.focus-visible) {
  outline: 0 !important;
  box-shadow: none !important;
}

*::selection {
  background: #96c6ff;
  color: #4824c1;
}

blockquote {
  margin: 30px 0 !important;
  border-left: 2px solid #eb4ce4 !important;
  padding-left: 41px !important;
}
